import { useState, useEffect } from "react";
import Title from "../../components/Common/Title";
import { MdCompare, MdEditNote } from "react-icons/md";
import { GiTrail } from "react-icons/gi";
import { TbCodePlus, TbApi } from "react-icons/tb";
import { Link } from "react-router-dom";

function ToolsPage() {
  const title = "Tools";
  const tools = [
    {
      title: "Compare orgs",
      description: "Compare classes between orgs",
      to: "compare",
      icon: <MdCompare size="64px" />,
    },
    {
      title: "Apex Builder",
      description: "Build and deploy best practice apex easily",
      to: "apex-builder",
      icon: <TbCodePlus size="64px" />,
    },
    {
      title: "Component Renamer",
      description: "Rename components programatically",
      to: "component-renamer",
      icon: <MdEditNote size="64px" />,
    },
    {
      title: "REST",
      description: "Explore the REST API",
      to: "rest",
      icon: <TbApi size="64px" />,
    },
    {
      title: "Trail Builder",
      description: "Build trails for users",
      to: "trail-builder",
      icon: <GiTrail size="64px" />,
    },
  ];
  function card(tool) {
    return (
      <div className="m-4">
        <Link
          className="card h-auto w-full border-[1px] border-primary shadow-xl hover:btn-primary"
          to={tool.to}
        >
          <figure className="rounded px-10 pt-10">{tool.icon}</figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">{tool.title}</h2>
            <p>{tool.description}</p>
            {/* <div className="card-actions">
            <button className="btn-primary btn">Buy Now</button>
          </div> */}
          </div>
        </Link>
      </div>
    );
  }
  function wrap(el, key) {
    return (
      <div key={key} className="w-full md:w-1/2 lg:w-1/4">
        {el}
      </div>
    );
  }

  const cards = tools.map((tool) => wrap(card(tool), tool.title));

  return (
    <>
      <Title title={title} />
      <div className="flex p-6">
        <div className="flex w-full flex-wrap">{cards}</div>
      </div>
    </>
  );
}

export default ToolsPage;
