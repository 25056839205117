import { useContext, useState } from "react";
import Tabs from "./Tabs";
import Editor from "@monaco-editor/react";
import { VscDebugStart, VscListSelection } from "react-icons/vsc";

import TestResult from "./TestResult";
import UserContext from "../../context/UserContext";

function ExecutionPanel({ data, execute, executedCode, result }) {
  const { user } = useContext(UserContext);

  //console.log("ExecutionPanel |", data);

  const [activeTab, setActiveTab] = useState("Execution");

  // const [currentApexText, setApexText] = useState(arrayToCode(data?.apex));
  // const [currentTestText, setTestText] = useState(arrayToCode(data?.test));

  const renderedText = user.loggedIn
    ? "Solution here"
    : "Log in to see Solution";

  const tabs = {
    Execution: <VscDebugStart />,
    Solution: <VscListSelection />,
  };
  const tabsClass = {
    true: "text-secondary",
    false: "text-disabled",
  };

  function handleExecuteClick() {
    execute();
  }

  function handleResponse(response) {
    if (response) {
      if (response.success) {
        return <p>Success!</p>;
      } else if (response.compileProblem) {
        return <TestResult result={response.compileProblem} />;
      } else if (response.exceptionMessage) {
        return getFailedTests(response.exceptionMessage);
      }
    }
  }

  function getFailedTests(message) {
    // console.error("getFailedTests 1", message);
    // const b = message?.split("ApexException: ");
    // //console.log(b);
    // const c = b[1];
    // const d = c.split("|");
    // console.error("getFailedTests 1A", b, c, d);
    const failedTests = message?.split("ApexException: ")[1].split("|");
    // console.error("getFailedTests 2", failedTests);
    return failedTests?.map((data) => {
      const t = JSON.parse(data);
      return <TestResult key={t.id} result={t} />;
    });
  }

  const renderedResponse = handleResponse(result);
  const verifyButton = user.loggedIn ? (
    <button
      onClick={() => handleExecuteClick()}
      className="btn-secondary btn w-full"
    >
      Verify
    </button>
  ) : (
    <button className="btn-disabled btn w-full">Log in to Verify</button>
  );
  //console.log("EXEC | user.loggedIn", user.loggedIn);
  return (
    <>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsClass={tabsClass}
      />
      <div className="p-2" />
      {/* <Title title={user?.loggedIn} /> */}
      {activeTab === "Solution" && (
        <Editor
          height="48vh"
          path={activeTab}
          defaultLanguage="apex"
          defaultValue={
            user?.loggedIn ? renderedText : "Log in to see Solution"
          }
          options={{
            wordWrap: "on",
            minimap: {
              enabled: false,
            },
            readOnly: true,
          }}
        />
      )}
      {activeTab === "Execution" && (
        <div>
          {verifyButton}
          <div className="p-2" />
          {user.loggedIn && renderedResponse && (
            <div className="max-h-[36rem] flex-col justify-center overflow-y-scroll">
              {renderedResponse}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ExecutionPanel;
