import CodingPanel from "./CodingPanel";
import ChallengePanel from "./ChallengePanel";
import { useContext, useEffect, useState, useRef } from "react";
import DataContext from "../../context/DataContext";
import ExecutionPanel from "./ExecutionPanel";
import UserContext from "../../context/UserContext";
import {
  arrayToCode,
  codeToArray,
  arrayToString,
} from "../../utils/codeParser";
import PanelContainer from "../Common/PanelContainer";
import { call_refreshAccessToken } from "../../utils/serverCallouts";

function ChallengeContainer({ challengeId }) {
  //console.log("ChallengeContainer |", challengeId);

  const { getDefaultChallengeFromId } = useContext(DataContext);
  const { getUserChallengeFromId, user, updateUserInfo } =
    useContext(UserContext);

  const currentApexInput = useRef(null);
  const currentTestInput = useRef(null);
  const [executedCode, setExecutedCode] = useState("");
  const [result, setResult] = useState();
  let tests = [];

  const [defaultChallengeData, setDefaultChallengeData] = useState();
  useEffect(() => {
    let challenge = getDefaultChallengeFromId(challengeId);
    //console.log("ChallengeContainer | UE | defaultChallenge", challenge);
    if (challenge) {
      const userChallenge = getUserChallengeFromId(challengeId);
      //console.log("ChallengeContainer | userChallenge", userChallenge);
      if (userChallenge) {
        challenge.apex = userChallenge.code.apex;
        //console.log("ChallengeContainer | apex NEW", challenge.apex);
      }
      currentApexInput.current = challenge.apex;
    }
    setDefaultChallengeData(challenge);
    // const autoSave = setInterval(() => {
    //   //console.log("SI");
    // }, 5000);

    // return clearInterval(autoSave);
  }, [defaultChallengeData]);

  function execute() {
    // //console.log("execute() ----");
    // printValues();
    // //console.log("execute() EOF ----");
    executeAnonymous();
  }

  function onClick() {
    printValues();
  }

  function printValues() {
    //console.log("currentApexInput", currentApexInput);
    //console.log("currentTestInput", currentTestInput);
  }

  async function executeAnonymous() {
    let challenge = getDefaultChallengeFromId(challengeId);
    //console.log("ChallengeContainer | executeAnonymous | challenge", challenge);
    if (challenge != null) {
      // console.log(
      //   "ChallengeContainer | executeAnonymous",
      //   currentApexInput.current
      // );

      const method = {
        name: challenge.method_name,
        params: challenge.method_params,
        returnType: challenge.method_return,
      };

      const body = {
        domain: user.userInfo.sf_org,
        executed: generateBody(
          arrayToString(currentApexInput.current),
          method,
          challenge.validation
        ),
      };
      setExecutedCode(body.executed);

      //console.log("ChallengeContainer | executeAnonymous | body", body);
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          sf_access_token: user.userInfo.sf_access_token,
        },
        body: JSON.stringify(body),
      };
      const serverHost = window.location.host.includes("localhost")
        ? process.env.REACT_APP_SERVER_HOST_DEV
        : process.env.REACT_APP_SERVER_HOST_PROD;
      const urlExecute = `${serverHost}/api/sf/execute`;
      // //console.log(
      //   "ChallengeContainer | executeAnonymous | urlExecute",
      //   urlExecute,
      //   user.userInfo.sf_access_token
      // );
      try {
        //console.log("ChallengeContainer | executeAnonymous | 1");
        let response = await fetch(urlExecute, options);
        // console.log(
        //   "ChallengeContainer | executeAnonymous | 2",
        //   response.toString()
        // );
        let result = await response.json();
        //console.log("ChallengeContainer | executeAnonymous | 3");
        // console.warn(result);
        if (!result.success) {
          //console.log("ChallengeContainer | executeAnonymous | 4");
          if (result.message === "007" || result.message === "008") {
            //console.log("ChallengeContainer | executeAnonymous | 5");
            let newToken = await call_refreshAccessToken(user, updateUserInfo);

            options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                sf_access_token: newToken,
              },
              body: JSON.stringify(body),
            };
            // console.log(
            //   "ChallengeContainer | executeAnonymous | 5B ",
            //   newToken
            // );
            response = await fetch(urlExecute, options);
            result = await response.json();
            //console.log("ChallengeContainer | executeAnonymous | 6");
          }
        }
        //console.log("ChallengeContainer | executeAnonymous | 7");
        if (result.success) {
          // console.log(
          //   "############",
          //   result?.data?.exceptionMessage
          //     ?.split("ApexException: ")[1]
          //     .split("|")[1]
          // );
          setResult(result.data);
        } else {
          //console.log("ChallengeContainer | executeAnonymous | result.fail");
          throw new Error(result.message);
        }
      } catch (err) {
        //console.log("ChallengeContainer | executeAnonymous | catch", err);
      }
      // fetch(urlExecute, options)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     //console.log(
      //       "############",
      //       data?.exceptionMessage?.split("ApexException: ")[1].split("|")[1]
      //     );
      //     setResult(data);
      //   })
      //   .catch((err) => {
      //     //console.log("ChallengeContainer | executeAnonymous | catch", err);
      //   });
    }
  }

  function generateBody(apex, method, validation) {
    let returnString = apex + buildTestApex(method, validation);

    return returnString;
  }

  function buildTestApex(method, validation) {
    // console.log(
    //   "ChallengeContainer | buildTestApex ",
    //   method,
    //   "][",
    //   validation
    // );
    const args = Object.keys(validation[0]).filter((arg) => arg !== "return");
    //console.log("ChallengeContainer | buildTestApex args", args);
    const validationStrings = validation
      .map((v, i) => {
        const methodCall = `${method.name}(${args
          .map((arg) => v[arg])
          .join(",")})`;
        // Can just use Object.values()?
        const varName = `x${i}`;
        const varLine = `${method.returnType} ${varName} = ${methodCall};`;
        const ifLine = `if(${varName}==${v.return})`;
        // const resultLine = `'{"id":${i}, "test":"${methodCall} == ${v.return}", "result":'+${varName}+'}'`;
        const goodResultLine = `'{"id":"${
          i + 1
        }", "success":true, "expected":"${
          v.return
        }", "result":"'+${varName}+'"}'`;
        const goodOutputLine = `fails.add(${goodResultLine});`;
        const elseLine = `else `;
        const badResultLine = `'{"id":"${
          i + 1
        }", "success":false, "expected":"${
          v.return
        }", "result":"'+${varName}+'"}'`;
        const badOutputLine = `fails.add(${badResultLine});`;
        const line =
          varLine + ifLine + goodOutputLine + elseLine + badOutputLine;
        // console.error(
        //   `ChallengeContainer | buildTestApex | v,i,args  [${v},${i}, ${args}] [${line}]`
        // );
        return line;
      })
      .join("");
    let returnString =
      "String[] fails = new String[]{};" +
      validationStrings +
      "public class ApexException extends Exception {}" +
      "if(!fails.isEmpty()) throw new ApexException(String.join(fails, '|'));";

    // Integer[] fails = Integer[]{};
    // if(sum1(1,1) != 2) fails.add('{id: 1, test: ); ==
    // if(sum1(50,-49) != 1) fails.add(2);
    // throw new ApexException(fails);

    /* 
    tests = [
      {
        id: 1,
        test: 

      }
    ]
    */

    return returnString;
  }
  return (
    <div className="flex">
      <div className="flex w-full flex-wrap">
        <PanelContainer>
          <ChallengePanel challenge={defaultChallengeData} />
        </PanelContainer>
        <PanelContainer>
          <CodingPanel
            defaultChallengeData={defaultChallengeData}
            currentApexInput={currentApexInput}
            currentTestInput={currentTestInput}
          />
        </PanelContainer>
        <PanelContainer>
          <ExecutionPanel
            challenge={defaultChallengeData}
            execute={execute}
            executedCode={executedCode}
            result={result}
          />
        </PanelContainer>
      </div>
    </div>
  );
}

export default ChallengeContainer;
