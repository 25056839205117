export const serverHost = window.location.host.includes("localhost")
  ? process.env.REACT_APP_SERVER_HOST_DEV
  : process.env.REACT_APP_SERVER_HOST_PROD;

export const clientHost = window.location.host.includes("localhost")
  ? process.env.REACT_APP_CLIENT_HOST_DEV
  : process.env.REACT_APP_CLIENT_HOST_PROD;

export const redirectURL = `${clientHost}/oauth2/callback`;

export const sfAuthURL = `services/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_SF_CLIENT_ID}&redirect_uri=${redirectURL}`;
export const sfProductionURL = `https://login.salesforce.com/${sfAuthURL}`;
export const sfSandboxURL = `https://login.salesforce.com/${sfAuthURL}`;
export const sfDomainURL = (domain) =>
  `${domain.endsWith("/") ? domain : `${domain}/`}${sfAuthURL}`;
