import { serverHost } from "./endpoints";

export async function call_setUserInfoFromCode(
  code,
  user,
  updateUserInfo,
  logIn,
  logOut,
  setLoginError,
  setChallenges,
  userChallengesData,
  orgDomain
) {
  try {
    const tokenURL = `${serverHost}/api/sf/auth/user?code=${code}`;

    let response = await fetch(tokenURL);
    let result = await response.json();
    if (!result.success) {
      if (result.message === "007") {
        call_refreshAccessToken(user, updateUserInfo);
        response = await fetch(tokenURL);
        result = await response.json();
      }
    }
    if (result.success) {
      updateUserInfo({
        sf_username: result.data.sf_username,
        sf_org: orgDomain,
        sf_org_type: "production",
        sf_profile: result.data.sf_profile,
        sf_access_token: result.data.sf_access_token,
        sf_refresh_token: result.data.sf_refresh_token,
      });
      logIn();
      setChallenges(userChallengesData);
    } else {
      throw new Error(result.data.error);
    }
  } catch (err) {
    //console.log("Callback | setUserInfoFromCode | catch", err);
    logOut();
    setLoginError("Server down");
  } finally {
    //console.log("Callback | setUserInfoFromCode | finally", user);
  }

  // fetch(tokenURL)
  //   .then((response) => {
  //     //console.log("Callback | setUserInfoFromCode | then1");
  //     return response.json();
  //   })
  //   .then((result) => {
  //     //console.log("Callback | setUserInfoFromCode | then2", result);
  //     if (result.success) {
  //       updateUserInfo({
  //         sf_username: result.data.sf_username,
  //         sf_org: orgDomain,
  //         sf_org_type: "production",
  //         sf_profile: result.data.sf_profile,
  //         sf_access_token: result.data.sf_access_token,
  //         sf_refresh_token: result.data.sf_refresh_token,
  //       });
  //       logIn();
  //       setChallenges(userChallengesData);
  //       setDefaultChallenges(challengeData);
  //     } else if (result.message === "007") {
  //       call_refreshAccessToken(user, updateUserInfo);
  //     } else {
  //       throw new Error(result.data.error);
  //     }
  //   })
  //   .catch((err) => {
  //     //console.log("Callback | setUserInfoFromCode | catch", err);
  //     logOut();
  //     setLoginError("Server down");
  //   })
  //   .finally(() => {
  //     //console.log("Callback | setUserInfoFromCode | finally", user);
  //   });
  // // //console.log("data", JSON.stringify(data));
  // //console.log("LoginButton 2 | UE | user", user);
  // //setChallenges(data);
}

export async function call_refreshAccessToken(user, updateUserInfo) {
  const tokenURL = `${serverHost}/api/sf/auth/refresh?refresh=${user?.userInfo?.sf_refresh_token}`;
  //console.log("SessionInfo | refreshAccessToken | tokenURL", tokenURL);
  let token;
  return await fetch(tokenURL)
    .then((response) => response.json())
    .then((result) => {
      // console.log(
      //   "SessionInfo | refreshAccessToken | then",
      //   result.data.sf_access_token
      // );
      updateUserInfo({
        sf_access_token: result.data.sf_access_token,
      });
      return result.data.sf_access_token;
    })
    // .finally(() => {
    //   token =
    // })
    .catch((err) => {
      //console.log("SessionInfo | refreshAccessToken | catch", err);
    });
  // //console.log("SessionInfo | refreshAccessToken | END");
}

export async function call_revokeAccessToken(user, logOut) {
  const tokenURL = `${serverHost}/api/sf/auth/revoke?token=${user?.userInfo?.sf_refresh_token}&domain=${user?.userInfo?.sf_org}`;
  //console.log("SessionInfo | revokeAccessToken | tokenURL", tokenURL);
  fetch(tokenURL)
    .then((response) => response.json())
    .then((data) => {
      //console.log("SessionInfo | revokeAccessToken | then", data);
      logOut();
    })
    .catch((err) => {
      //console.log("SessionInfo | revokeAccessToken | catch", err);
    });
  //console.log("SessionInfo | revokeAccessToken | END");
}

export function getChallengeData() {
  //console.log("getChallengeData START");
  return fetch(`${serverHost}/api/db`)
    .then((response) => response.json())
    .then((data) => data || [])
    .finally(() => console.log("getChallengeData FINISHED"));
}
