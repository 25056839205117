import { useState } from "react";
import Title from "../../components/Common/Title";

function TrailsPage() {
  const [mode, setMode] = useState(1);
  const codeClass =
    mode === 1 ? "mockup-code" : "mockup-code bg-base-200 text-base-content";
  const panel = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <div className={codeClass}>
        <div className="flex flex-col">
          <h2 className="ml-8 text-lg font-bold">
            <pre data-prefix=">" className="text-primary">
              <code>Apex Developer</code>
            </pre>
          </h2>
          <code className="flex justify-center">
            <ul className="steps steps-vertical">
              <li className="step-primary step font-semibold">Apex Basics</li>
              <li className="step-primary step font-semibold">
                SOQL Fundamentals
              </li>
              <li className="step">Advanced Apex</li>
              <li className="step">Best Practices</li>
            </ul>
          </code>
        </div>
      </div>
    </div>
  );
  const panel2 = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <div className={codeClass}>
        <div className="flex flex-col">
          <h2 className="ml-10 text-lg font-bold">
            <pre data-prefix=">" className="text-secondary">
              <code>Web Developer</code>
            </pre>
          </h2>
          <code className="flex justify-center">
            <ul className="steps steps-vertical">
              <li className="step-secondary step font-semibold">Apex Basics</li>
              <li className="step">Visualforce</li>
              <li className="step">JavaScript</li>
              <li className="step">LWC</li>
              <li className="step">Web Security</li>
              <li className="step">Best Practices</li>
            </ul>
          </code>
        </div>
      </div>
    </div>
  );
  const panel3 = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <div className={codeClass}>
        <div className="flex flex-col">
          <h2 className="ml-8 text-lg font-bold">
            <pre data-prefix=">" className="text-accent">
              <code>Feature Tester</code>
            </pre>
          </h2>
          <code className="flex justify-center">
            <ul className="steps steps-vertical">
              <li className="step-accent step">Apex Basics</li>
              <li className="step-accent step">SOQL Fundamentals</li>
              <li className="step-accent step">Unit Tests</li>
              <li className="step">Test Factory</li>
              <li className="step">Automated Testing</li>
              <li className="step">Test Planning</li>
            </ul>
          </code>
        </div>
      </div>
    </div>
  );

  const step1 = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <h2 className="flex justify-center text-lg font-bold">Apex Developer</h2>
      <ul className="steps steps-vertical">
        <li className="step-primary step font-semibold">Apex Basics</li>
        <li className="step-primary step font-semibold">SOQL Fundamentals</li>
        <li className="step">Advanced Apex</li>
        <li className="step">Best Practices</li>
      </ul>
    </div>
  );
  const step2 = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <h2 className="flex justify-center text-lg font-bold">Web Developer</h2>
      <ul className="steps steps-vertical">
        <li className="step-secondary step font-semibold">Apex Basics</li>
        <li className="step">Visualforce</li>
        <li className="step">JavaScript</li>
        <li className="step">LWC</li>
        <li className="step">Web Security</li>
        <li className="step">Best Practices</li>
      </ul>
    </div>
  );
  const step3 = (
    <div className="mb-2 flex w-full justify-center md:w-1/2 lg:w-1/3">
      <h2 className="flex justify-center text-lg font-bold">Testing</h2>
      <ul className="steps steps-vertical">
        <li className="step-accent step">Apex Basics</li>
        <li className="step-accent step">SOQL Fundamentals</li>
        <li className="step-accent step">Unit Tests</li>
        <li className="step">Test Factory</li>
        <li className="step">Automated Testing</li>
        <li className="step">Test Planning</li>
      </ul>
    </div>
  );

  function handleChangeClick() {
    setMode((curr) => (curr % 3) + 1);
  }
  return (
    <>
      <Title title="Trails" />
      <div className="flex justify-center">
        <button className="btn-primary btn m-4" onClick={handleChangeClick}>
          Change mode
        </button>
      </div>
      <div className="flex justify-center">
        <div className="flex w-4/5 flex-wrap">
          {(mode === 1 || mode === 2) && panel}
          {(mode === 1 || mode === 2) && panel2}
          {(mode === 1 || mode === 2) && panel3}

          {mode === 3 && step1}
          {mode === 3 && step2}
          {mode === 3 && step3}
        </div>
      </div>
    </>
  );
}

export default TrailsPage;
