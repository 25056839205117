import React from "react";
import {
  VscBracketDot,
  VscDatabase,
  VscCode,
  VscChevronRight,
} from "react-icons/vsc";
import { Link } from "react-router-dom";

function ChallengeIndex() {
  const totalCount = 300;
  const data = [
    {
      heading: "Apex coding challenges",
      sub: "The core skills for all developers",
      count: "50",
      icon: <VscBracketDot size="36px" className="fill-primary" />,
      color: "primary",
    },
    {
      heading: "SOQL query challenges",
      sub: "Data controls everything",
      count: "10",
      icon: <VscDatabase size="36px" className="fill-error" />,
      color: "error",
    },
    {
      heading: "LWC Quizzes",
      sub: "Visualforce is dead",
      count: "4",
      icon: <VscCode size="36px" className="fill-accent" />,
      color: "accent",
    },
  ];

  const renderedRows = data?.map((row) => {
    return (
      <div key={row.heading} className="w-full md:w-1/2 lg:w-1/3">
        <div
          className="card m-2 h-96 border border-base-200 bg-base-100 shadow-xl"
          key={row.heading}
        >
          <figure className="px-10 pt-10">{row.icon}</figure>
          <div className="card-body items-center text-center">
            <h2 className={`card-title text-${row.color}`}>{row.heading}</h2>
            <p>{row.sub}</p>
            <div className="card-actions">
              <Link to="/challenges" className={`btn-${row.color} btn`}>
                {row.count}+<VscChevronRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
      // <div
      //   key={row.heading}
      //   className={`grid w-2/5 grid-cols-10 gap-4 ${classBorder} h-full rounded border-2 border-slate-300 bg-base-100 p-4 shadow-xl`}
      // >
      //   <div className={`col-span-1`}>
      //     <div className="grid h-full place-items-center">
      //       <span className="">{row.icon}</span>
      //     </div>
      //   </div>
      //   <div className={`col-span-8`}>
      //     <p className="m-2 my-2 text-2xl font-bold ">{row.heading}</p>
      //     <p className={`m-2 my-2 text-lg ${row.color}`}>{row.sub}</p>
      //   </div>
      //   <div className={`col-span-1`}>
      //     <div className="grid h-full place-items-center">
      //       <Link
      //         to="/challenges"
      //         className="btn-primary btn h-4 w-20 rounded-3xl"
      //       >
      //         {row.count}+<VscChevronRight />
      //       </Link>
      //     </div>
      //   </div>
      // </div>
    );
  });
  return <div className="flex w-full flex-wrap">{renderedRows}</div>;
}

export default ChallengeIndex;
