import { useReducer, createContext, useEffect } from "react";
import { userReducer, initialState } from "./reducer/userReducer";
import { USER } from "./reducer/actionTypes";

const UserContext = createContext();

export function UserProvider({ children }) {
  const localKey = "userOld";
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    // //console.log("UserContext | loadLocal[]");
    loadLocal();
  }, []);

  useEffect(() => {
    // //console.log("UserContext | useEffect[state]");
    saveLocal();
  }, [state]);

  function logIn() {
    dispatch({ type: USER.LOG_IN });
  }

  function logOut() {
    dispatch({ type: USER.LOG_OUT });
  }

  function setLoggingIn(loggingIn) {
    dispatch({ type: USER.LOGGING_IN, payload: { loggingIn } });
  }

  function setLoginError(loginError) {
    dispatch({ type: USER.LOGIN_ERROR, payload: { loginError } });
  }

  function setUser(user) {
    if (user.loginError && (user.loggedIn || user.loggingIn)) {
      dispatch({
        type: USER.SET_USER,
        payload: { ...user, loggedIn: false, loggingIn: false },
      });
    } else {
      dispatch({ type: USER.SET_USER, payload: user });
    }
  }

  function setUserInfo(userInfo) {
    dispatch({ type: USER.SET_USER_INFO, payload: { userInfo } });
  }

  function resetUser() {
    dispatch({ type: USER.RESET_USER });
  }

  function updateUserInfo(userInfo) {
    dispatch({ type: USER.UPDATE_USER_INFO, payload: { userInfo } });
  }

  function setChallenges(challenges) {
    dispatch({ type: USER.SET_CHALLENGES, payload: { challenges } });
  }

  function upsertChallenge(index, challenge) {
    dispatch({ type: USER.SET_CHALLENGES, payload: { index, challenge } });
  }

  function setReturnPage(returnPage) {
    dispatch({ type: USER.SET_RETURNPAGE, payload: { returnPage } });
    saveLocal();
  }

  function getUserChallengeFromId(id) {
    const filteredChallenge = state?.challenges?.filter(
      (challenge) => challenge.id === Number(id)
    );
    if (filteredChallenge && filteredChallenge.length !== 0) {
      return filteredChallenge[0];
    }
    // console.error("No User Challenge data found with ID:", id);
    return null;
  }

  function saveLocal() {
    ////console.log("UserContext | saveLocal()");
    window.localStorage.setItem(localKey, JSON.stringify(state));
  }

  function loadLocal() {
    const localUser = window.localStorage.getItem(localKey);
    ////console.log("UserContext | loadLocal()", JSON.stringify(localUser));
    if (localUser) {
      setUser(JSON.parse(localUser));
    }
  }

  return (
    <UserContext.Provider
      value={{
        user: state,
        logIn,
        logOut,
        setLoggingIn,
        setLoginError,
        setUser,
        setUserInfo,
        resetUser,
        updateUserInfo,
        setChallenges,
        upsertChallenge,
        setReturnPage,
        getUserChallengeFromId,
        saveLocal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
