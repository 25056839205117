import { useState, useEffect } from "react";
import Title from "../../components/Common/Title";
import NewChallengeForm from "../../components/Challenges/NewChallengeForm";

function NewChallengePage() {
  return (
    <>
      <Title title="New Challenge" />
      <NewChallengeForm />
    </>
  );
}

export default NewChallengePage;
