function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42.667"
      height="42.667"
      version="1.0"
      viewBox="0 0 32 32"
      className="h-9 sm:h-9 fill-primary"
    >
      <path d="M15.6 12.6c-1.5 4.7-2.5 8.8-2.2 9.1.9.9 1.1.5 4-8.2 2.8-8.7 3-9.5 1.8-9.5-.4 0-2 3.9-3.6 8.6z" />
      <path d="M6.8 8.6C3 10.5 0 12.7 0 13.5c0 .8 1.3 2.1 3 3 2.4 1.2 3 2.2 3 4.8 0 4 .7 5 4.7 7.1 3.6 1.8 4.3 1.9 4.3.8 0-.5-1.6-1.6-3.5-2.5C8.7 25.3 8 24.5 8 22c0-2.2.4-2.8 1.5-2.4 3.4 1.4 1.2-1.4-2.5-3.1-2.3-1-4.3-2.2-4.6-2.7-.3-.4 2.4-2.2 6.1-4C12.1 8 15 6.2 15 5.8c0-1.3-1.1-.9-8.2 2.8zM21 7.8c0 .5 1.8 1.7 4 2.7 2.3 1 4.3 2.2 4.6 2.6.3.5-2.4 2.3-5.8 4-7.2 3.6-7.3 3.7-6.6 4.5.4.3 2-.2 3.7-1.1l3.1-1.6V22c0 2.5-.6 3.3-3.5 4.7-1.9.9-3.5 2-3.5 2.5 0 1.1.7 1 4.3-.8 4-2.1 4.7-3.1 4.7-7.1 0-1.7.5-3.5 1.1-3.8 1.6-1.1 2.2.1 1.1 2.2-.9 1.6-.7 1.9 1.3 1.9s2.2-.3 1.2-2.3c-.8-1.7-.7-2.7.1-3.5C33 13.6 32 12.1 27 9.5c-5.4-2.7-6-2.9-6-1.7z" />
    </svg>
  );
}

export default Logo;
