import Title from "../components/Common/Title";
import ChallengeIndex from "../components/Challenges/ChallengeIndex";
import ServerStatus from "../components/Main/ServerStatus";

function HomePage() {
  return (
    <>
      <div className="flex h-full flex-col">
        <Title title="Home" hasBreadCrumbs={true} />
        <ChallengeIndex />
        <ServerStatus />
      </div>
    </>
  );
}

export default HomePage;
