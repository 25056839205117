import { useReducer, createContext, useEffect } from "react";
import { dataReducer, initialState } from "./reducer/dataReducer";
import { DATA } from "./reducer/actionTypes";

const DataContext = createContext();

export function DataProvider({ children }) {
  const localKey = "data";
  const [state, dispatch] = useReducer(dataReducer, initialState);

  useEffect(() => {
    // //console.log("DataContext | loadLocal[]");
    loadLocal();
  }, []);

  useEffect(() => {
    // //console.log("DataContext | useEffect[state]");
    saveLocal();
  }, [state]);

  function setData(data) {
    dispatch({ type: DATA.SET_DATA, payload: data });
  }

  function resetData(challenges) {
    dispatch({ type: DATA.RESET_DATA });
  }

  function setChallenges(challenges) {
    dispatch({ type: DATA.SET_CHALLENGES, payload: { challenges } });
  }

  function addChallenge(challenge) {
    dispatch({ type: DATA.ADD_CHALLENGE, payload: { challenge } });
  }

  function removeChallenge(index) {
    dispatch({ type: DATA.REMOVE_CHALLENGE, payload: { index } });
  }

  function updateChallenge(index, challenge) {
    dispatch({ type: DATA.UPDATE_CHALLENGE, payload: { index, challenge } });
  }

  function getDefaultChallengeFromId(id) {
    const filteredChallenge = state?.challenges?.filter((challenge) => {
      return Number(challenge.id) === Number(id);
    });
    if (filteredChallenge && filteredChallenge.length !== 0) {
      return filteredChallenge[0];
    }
    // console.error("No Default Challenge found with ID:", id);
    return null;
  }

  function saveLocal() {
    ////console.log("DataContext | saveLocal()");
    window.localStorage.setItem(localKey, JSON.stringify(state));
  }

  function loadLocal() {
    const localData = window.localStorage.getItem(localKey);
    ////console.log("DataContext | loadLocal()", localData?.challenges?.length);
    if (localData) {
      setData(JSON.parse(localData));
    }
  }

  return (
    <DataContext.Provider
      value={{
        data: state,
        setChallenges,
        addChallenge,
        removeChallenge,
        updateChallenge,
        getDefaultChallengeFromId,
        resetData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataContext;
