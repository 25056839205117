import { Link, useLocation } from "react-router-dom";
import LoginButton from "./LoginButton";
import Logo from "./Logo";
import SettingsButton from "./SettingsButton";
import StatusIcon from "./StatusIcon";
import ThemePicker from "./ThemePicker";
import UsernameIcon from "./UsernameIcon";

const links = {
  Challenges: "/challenges",
  Trails: "/trails",
  Tools: "/tools",
  About: "/about",
};

function NavbarTop() {
  const { pathname } = useLocation();
  const renderedLinks = Object.keys(links)?.map((link) => (
    <li key={link}>
      <Link
        to={links[link]}
        className={`btn-link btn no-underline focus:bg-transparent ${
          pathname.includes(links[link]) ? "text-primary" : ""
        }`}
      >
        {link}
      </Link>
    </li>
  ));

  const renderedLinksHamburger = Object.keys(links)?.map((link) => (
    <li key={link}>
      <Link
        to={links[link]}
        // className="btn btn-link no-underline focus:bg-transparent"
        className={`justify-between no-underline ${
          pathname.includes(links[link]) ? "text-primary" : ""
        }`}
      >
        {link}
      </Link>
    </li>
  ));

  return (
    <div className="bg-base-00 navbar">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn-ghost btn lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box menu-compact mt-3 w-52 bg-base-100 p-2 shadow"
          >
            {renderedLinksHamburger}
          </ul>
        </div>
        <div className="indicator">
          <Link
            to="/"
            //className="btn btn-link no-underline focus:bg-transparent normal-case text-xl"
            className="btn-link btn text-xl normal-case no-underline"
          >
            <Logo />
            <span className="hidden sm:flex">Feel the Force </span>
            <span className="indicator-center badge-primary badge indicator-item sm:indicator-end">
              BETA
            </span>
          </Link>
        </div>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">{renderedLinks}</ul>
      </div>
      <div className="navbar-end">
        <div className="mx-4 flex hidden w-48 lg:block">
          <ThemePicker />
        </div>
        <StatusIcon />
        <UsernameIcon />
        <LoginButton />

        <SettingsButton />
      </div>
    </div>
  );
}

export default NavbarTop;
