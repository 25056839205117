import { useState, useEffect, useContext } from "react";
import Title from "../../components/Common/Title";
import UserContext from "../../context/UserContext";
import DataContext from "../../context/DataContext";
import ThemePicker from "../../components/Main/ThemePicker";
import SessionInfo from "./SessionInfo";
import { Link } from "react-router-dom";

function SettingsPage() {
  const { user, updateUserInfo, resetUser, saveLocal } =
    useContext(UserContext);
  const { resetData } = useContext(DataContext);

  const [myDomain, setMyDomain] = useState(user?.userInfo?.sf_mydomain);
  const [showMessage, setShowMessage] = useState(false);
  const [saveButtonClass, setSaveButtonClass] = useState("main");

  useEffect(() => {
    //console.log("Settings | UE | user", user);
  }, [user]);

  const saveMessage = (
    <p className="text-lg font-semibold text-gray-400">
      <em>Settings saved</em>
    </p>
  );

  function handleOnChange(e) {
    setMyDomain(e.target.value);
  }

  function handleSaveClicked() {
    setSaveButtonClass("disabled");
    //console.log(myDomain);
    updateUserInfo({
      sf_mydomain: myDomain,
    });
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setSaveButtonClass("main");
    }, 1000);
  }

  function handleClearClicked() {
    resetUser();
    resetData();
  }

  return (
    <>
      <Title title="Settings" />
      <div className="flex h-full justify-center">
        <div className="form-control m-4">
          <div>
            <label className="label">
              <span className="label-text">My Domain</span>
            </label>
            <label className="input-group">
              <span>URL</span>
              <input
                value={myDomain || ""}
                onChange={handleOnChange}
                type="text"
                placeholder="https://mydomain.my.salesforce.com"
                className="input-bordered input w-full"
              />
            </label>

            <button
              className={`btn btn-${saveButtonClass} mr-1 mt-1 w-full`}
              onClick={handleSaveClicked}
            >
              Save Changes
            </button>
            <div className="my-4 w-full">
              <button
                className={`btn btn-${saveButtonClass} mr-1 mt-1`}
                onClick={handleClearClicked}
              >
                Clear Local Data
              </button>
              {showMessage && (
                <div className="flex justify-center">{saveMessage}</div>
              )}
              <Link to="/session" className={`btn-secondary btn mr-1 mt-1`}>
                Session Info
              </Link>
            </div>
            <div className="lg:hidden">
              <label className="label">
                <span className="label-text">Aesthetic</span>
              </label>
              <label className="input-group">
                <span>Theme</span>
                <ThemePicker />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingsPage;

// const fields = [1, 2, 3, 4, 5, 6];
// function field(name) {
//   return (
//     <div>
//       <label className="label">
//         <span className="label-text">{name}</span>
//       </label>
//       <label className="input-group">
//         <span>Side</span>
//         <input
//           type="text"
//           placeholder="Placeholder..."
//           className="input input-bordered w-96"
//         />
//       </label>
//     </div>
//   );
// }

// const renderedItems = fields.map((i) => field(i));
