import { useDispatch, useSelector } from "react-redux";
import {
  selectUserAll,
  selectUserInfo,
  selectUserChallenges,
  updateUser,
  addChallengeModified,
  resetUser,
} from "../features/user/userSlice";
import { useLoginQuery } from "../features/user/userApiSlice";

function Testing() {
  const dispatch = useDispatch();

  const userAll = JSON.stringify(useSelector(selectUserAll), null, 2);
  const userInfo = JSON.stringify(useSelector(selectUserInfo), null, 2);
  const userChallenges = JSON.stringify(
    useSelector(selectUserChallenges),
    null,
    2
  );

  function a() {
    dispatch(
      updateUser({ sf_username: "mheath@deloitte.co.uk", sf_boogie: "boobs" })
    );
  }

  function b() {
    dispatch(
      addChallengeModified({
        sf_username: "mheath@deloitte.co.uk",
        sf_boogie: "boobs",
      })
    );
  }
  function c() {
    dispatch(resetUser());
  }
  const {
    data: userAuth,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useLoginQuery();
  function d() {
    dispatch(resetUser());
  }

  return (
    <div className="bg-base-200">
      <div className="py-6">
        <div className="m-4 w-96">{userAll}</div>
        <div className="m-4 w-96">{userInfo}</div>
        <div className="m-4 w-96">{userChallenges}</div>
      </div>
      <button className="btn-primary btn m-2" onClick={a}>
        updateUser
      </button>
      <button className="btn-primary btn m-2" onClick={b}>
        addChallengeModified
      </button>
      <button className="btn-primary btn m-2" onClick={c}>
        resetUser
      </button>
      <button className="btn-primary btn m-2" onClick={d}>
        {JSON.stringify(userAuth)}
      </button>
      {}
    </div>
  );
}

export default Testing;
