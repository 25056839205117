import { useSelector, useDispatch } from "react-redux";
import { setTheme } from "../../features/theme/themeSlice";
import { selectTheme } from "../../features/theme/themeSlice";

const themes = [
  "corporate",
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
  "ftf",
];

function ThemePicker() {
  const currentTheme = useSelector(selectTheme);
  document.querySelector("html").setAttribute("data-theme", currentTheme);

  const dispatch = useDispatch();

  function handleThemeSelected(newTheme) {
    dispatch(setTheme(newTheme));
  }

  const options = themes.map((theme) => (
    <option value={theme} key={theme}>
      {theme.charAt(0).toUpperCase() + theme.slice(1)}
    </option>
  ));

  return (
    <select
      className="select-bordered select w-full max-w-xs"
      onChange={(e) => handleThemeSelected(e.target.value)}
      value={currentTheme}
    >
      {options}
    </select>
  );
}

export default ThemePicker;
