import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function ChallengeListRow({ item }) {
  const {
    id,
    title,
    description,
    hints,
    difficulty,
    tags,
    apex,
    test,
    solution,
    validation,
    active,
    contributor,
  } = item;

  let renderedTags = tags.map((tag) => {
    const badgeClass =
      tag === "Apex"
        ? "badge-primary"
        : tag === "Generic"
        ? "badge-secondary"
        : tag === "DML"
        ? "badge-accent"
        : "badge-error";
    return (
      <span key={tag} className={`badge ${badgeClass} mr-1`}>
        {tag}
        {/* {tag}{tagToCol[tag]} */}
      </span>
    );
  });

  // const inactive = Math.random() >= 0.5;
  const inactive = false;

  const navigate = useNavigate();
  const handleClick = useCallback(
    () => navigate(`/challenges/${item.id}`, { replace: false }),
    [navigate]
  );

  return (
    <tr
      className={!inactive ? "hover cursor-pointer" : ""}
      onClick={handleClick}
    >
      <th>{id}</th>
      <td>
        <span className="font-bold">{title}</span> <br /> {renderedTags}
      </td>
      <td>
        {description.length <= 50
          ? description
          : `${description.substr(0, 50).trim()}...`}
      </td>
      <td>{difficulty}</td>
      <td>
        <div className="flex items-center space-x-3">
          <div>
            <div className="font-bold">{contributor}</div>
            <div className="text-sm opacity-50">United Kingdom</div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default ChallengeListRow;
