import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { updateUser, resetUser } from "../../features/user/userSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "http://localhost:4000",
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    console.log("Refresh Access Token");
    const refreshResult = await baseQuery("/api/sf/auth/refresh");
    console.log(refreshResult);
    if (refreshResult?.data) {
      // const user = api.getState().user
      api.dispatch(updateUser({ sf_refresh_token: refreshResult.data }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(resetUser());
    }
  }
  return result;
};

export const apiSlice = createApi({
  //baseQuery: baseQueryWithReauth,
  baseQuery,
  endpoints: (builder) => ({}),
});
