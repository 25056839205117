import { Link } from "react-router-dom";
import Title from "../../components/Common/Title";
import ChallengeList from "../../components/Challenges/ChallengeList";

function ChallengeListPage() {
  return (
    <>
      <Title title="Challenges" />
      <div className="flex justify-center">
        <Link to="/challenges/new" className="btn-primary btn">
          New Challenge
        </Link>
      </div>
      <ChallengeList />
    </>
  );
}

export default ChallengeListPage;
