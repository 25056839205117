export const DATA = {
  SET_DATA: "SET_DATA",
  RESET_DATA: "RESET_DATA",
  SET_CHALLENGES: "SET_CHALLENGES",
  ADD_CHALLENGE: "ADD_CHALLENGE",
  REMOVE_CHALLENGE: "REMOVE_CHALLENGE",
  UPDATE_CHALLENGE: "UPDATE_CHALLENGE",
};

export const USER = {
  LOG_IN: "LOG_IN",
  LOG_OUT: "LOG_OUT",
  LOGGING_IN: "LOGGING_IN",
  LOGIN_ERROR: "LOGIN_ERROR",
  SET_USER: "SET_USER",
  SET_USER_INFO: "SET_USER_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  RESET_USER: "RESET_USER",
  UPDATE_USER: "UPDATE_USER",
  SET_CHALLENGES: "SET_CHALLENGES",
  UPSERT_CHALLENGE: "UPSERT_CHALLENGE",
  SET_RETURNPAGE: "SET_RETURNPAGE",
};

export const THEME = {
  SET_THEME: "SET_THEME",
};
