import ChallengeListHeader from "./ChallengeListHeader";
import ChallengeListRow from "./ChallengeListRow";
import ChallengeListRowSkeleton from "./ChallengeListRowSkeleton";
import { useGetChallengesQuery } from "../../features/challenges/challengesApiSlice";
import { format } from "date-fns";
function ChallengeList() {
  const {
    data: challenges,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetChallengesQuery();

  const colHeadings = [
    "#",
    "Title",
    "Description",
    "Difficulty",
    "Contributor",
  ];

  const renderedHeadings = (
    <thead>
      <tr>
        {colHeadings.map((col) => (
          <th key={col}>{col}</th>
        ))}
      </tr>
    </thead>
  );

  const styling = `m-4 ${
    isLoading
      ? "animate-pulse space-y-4 divide-y divide-base-200 overflow-x-auto "
      : " "
  }overflow-x-auto`;
  let content;
  if (isLoading) {
    content = colHeadings.map((item) => (
      <ChallengeListRowSkeleton key={item} />
    ));
  } else if (isError) {
    // console.error(JSON.stringify(error));
    return (
      <div className="m-4 flex justify-center">
        <h2 className="text-2xl font-bold text-error">
          Failed to fetch Challenges {format(new Date(), "HH:mm dd-MM-yy")}
        </h2>
      </div>
    );
  } else if (isSuccess) {
    content = challenges?.map((item) => (
      <ChallengeListRow key={item.id} item={item} />
    ));
  }

  return (
    <div className={styling}>
      <table className="table-compact table w-full">
        {renderedHeadings}
        <tbody>{content}</tbody>
      </table>
    </div>
  );
}

export default ChallengeList;
