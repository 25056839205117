import { useState } from "react";
import { VscWarning } from "react-icons/vsc";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

function StatusIcon() {
  const { user } = useContext(UserContext);
  const [showLoginStatusTooltip, setShowLoginStatusTooltip] = useState();
  return (
    user.loginError && (
      <div
        className={`tooltip ${
          showLoginStatusTooltip ? "tooltip-open" : ""
        } tooltip-bottom tooltip-error`}
        data-tip={user.loginError}
      >
        <VscWarning
          size="24px"
          className="m-2 cursor-pointer fill-error"
          onMouseEnter={() => setShowLoginStatusTooltip(true)}
          onMouseLeave={() => setShowLoginStatusTooltip(false)}
        />
      </div>
    )
  );
}

export default StatusIcon;
