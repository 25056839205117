import { Routes, Route, Navigate } from "react-router-dom";
import ChallengePage from "./pages/Challenges/ChallengePage";
import ChallengeListPage from "./pages/Challenges/ChallengeListPage";
import HomePage from "./pages/HomePage";
import UnknownPage from "./pages/Service/UnknownPage";
import TestingPage from "./pages/TestingPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import CallbackPage from "./pages/Service/CallbackPage";
import SessionInfo from "./pages/Settings/SessionInfo";
import HowPage from "./pages/Single/HowPage";
import NewChallengePage from "./pages/Challenges/NewChallengePage";
import ApiPage from "./pages/Single/ApiPage";
import TrailsPage from "./pages/Trails/TrailsPage";
import ToolsPage from "./pages/Tools/ToolsPage";
import Layout from "./components/Layout";
import ComparePage from "./pages/Tools/ComparePage";
import ApexBuilderPage from "./pages/Tools/ApexBuilderPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="trails" element={<TrailsPage />} />
        <Route path="challenges">
          <Route index element={<ChallengeListPage />} />
          <Route path="new" element={<NewChallengePage />} />
          <Route path=":id" element={<ChallengePage />} />
        </Route>
        <Route path="oauth2/callback" element={<CallbackPage />} />
        <Route path="session" element={<SessionInfo />} />
        <Route path="about" element={<HowPage />} />
        <Route path="api" element={<ApiPage />} />
        <Route path="tools" element={<ToolsPage />} />
        <Route path="not-found" element={<UnknownPage />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
        <Route path="tools">
          <Route index element={<ToolsPage />} />
          <Route path="compare" element={<ComparePage />} />
          <Route path="apex-builder" element={<ApexBuilderPage />} />
        </Route>
        <Route path="test" element={<TestingPage />} />
      </Route>
    </Routes>
  );
}

export default App;
