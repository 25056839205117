import { USER } from "./actionTypes";

export const initialState = {
  loggedIn: false,
  loggingIn: false,
  loginError: null,
  userInfo: {
    sf_username: null,
    sf_org: null,
    sf_org_type: null,
    sf_access_token: null,
    sf_refresh_token: null,
    sf_mydomain: null,
  },
  challenges: [],
  returnPage: null,
};

export const userReducer = (state, action) => {
  const debugs = false;
  const { type, payload } = action;
  //console.log("userReducer START ========================");
  // console.log(
  //   `userReducer | ${JSON.stringify(type)} | 
  //     ${JSON.stringify(payload)}`
  // );

  switch (type) {
    case USER.LOG_IN:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            loggedIn: true,
            loggingIn: false,
            loginError: null,
          })}`
        );
      return { ...state, loggedIn: true, loggingIn: false, loginError: null };

    case USER.LOG_OUT:
      if (debugs)
        console.log(`userReducer | new state: ${JSON.stringify(initialState)}`);
      return initialState;

    case USER.LOGGING_IN:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            loggingIn: payload.loggingIn,
          })}`
        );
      return { ...state, loggingIn: payload.loggingIn };

    case USER.LOGIN_ERROR:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            loginError: payload.loginError,
          })}`
        );
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: payload.loginError,
      };

    case USER.SET_USER:
      if (debugs)
        console.log(`userReducer | new state: ${JSON.stringify(payload)})}`);
      return payload;

    case USER.SET_USER_INFO:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            userInfo: payload.userInfo,
          })}`
        );
      return { ...state, userInfo: payload.userInfo };

    case USER.RESET_USER:
      if (debugs)
        console.log(`userReducer | new state: ${JSON.stringify(initialState)}`);
      return initialState;

    case USER.UPDATE_USER_INFO:
      const upUserState = state;
      upUserState.userInfo = {
        ...state.userInfo,
        ...payload.userInfo,
      };
      // if (debugs)
      //console.log(`userReducer | new state: ${JSON.stringify(upUserState)}`);
      return upUserState;

    case USER.SET_CHALLENGES:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            challenges: payload.challenges,
          })}`
        );
      return { ...state, challenges: payload.challenges };

    case USER.UPSERT_CHALLENGE:
      const upState = state;
      const index = payload.index;
      if (upState.indexOf(index) !== -1) {
        upState.challenges[payload.index] = payload.challenge;
      } else {
        upState.challenges.push(payload.challenge);
      }

      if (debugs)
        //console.log(`userReducer | new state: ${JSON.stringify(upState)}`);
      return upState;

    case USER.SET_RETURNPAGE:
      if (debugs)
        console.log(
          `userReducer | new state: ${JSON.stringify({
            ...state,
            returnPage: payload.returnPage,
          })}`
        );
      return { ...state, returnPage: payload.returnPage };

    default:
      throw new Error(
        `No action type for UserReducer: ${JSON.stringify(type)}`
      );
  }
};
