import { useState, useEffect } from "react";

function Content({children}) {
  return (
    <section className="section">
      {children}
    </section>
  );
}

export default Content;
