import { useState, useEffect } from "react";
import Title from "../../components/Common/Title";

function UnknownPage() {
  return (
    <>
      <Title title="Unknown Page" />
    </>
  );
}

export default UnknownPage;
