function Hint({ hint }) {
  return (
    <div className="collapse my-2">
      <input type="checkbox" className="peer" />
      <div className="collapse-title bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content">
        <p className="text-lg font-bold">{hint.title}</p>
      </div>
      <div className="collapse-content bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content">
        <p className="text-md mt-2 font-semibold">{hint.body}</p>
      </div>
    </div>
  );
}

export default Hint;
