export const loadLocal = (key) => {
  try {
    console.info("Reading:", key);
    const serializedState = window.localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log("Failed to read", key);
    return undefined;
  }
};

export const saveLocal = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    window.localStorage.setItem(key, serializedValue);
    console.info("Saved:", key, serializedValue);
  } catch {
    console.log("Failed to write", key, value);
  }
};
