import { DATA } from "./actionTypes";

export const initialState = {
  challenges: [],
};

export const dataReducer = (state, action) => {
  const debugs = false;
  const { type, payload } = action;
  // console.log("dataReducer START ========================");
  // console.log(
  //   `dataReducer | ${JSON.stringify(type)} | challenges(${
  //     payload?.challenges?.length
  //   })}`
  // );

  switch (type) {
    case DATA.SET_DATA:
      if (debugs)
        console.log(`dataReducer | new state: ${JSON.stringify(payload)}`);
      return payload;

    case DATA.RESET_DATA:
      if (debugs)
        console.log(`dataReducer | new state: ${JSON.stringify(initialState)}`);
      return initialState;

    case DATA.SET_CHALLENGES:
      if (debugs)
        console.warn(
          `dataReducer | new state: ${JSON.stringify({
            ...state,
            challenges: payload.challenges,
          })}`
        );
      return {
        ...state,
        challenges: payload.challenges,
      };

    case DATA.ADD_CHALLENGE:
      if (debugs)
        console.log(
          `dataReducer | new state: ${JSON.stringify({
            ...state,
            challenges: state.challenges.push(payload.challenge),
          })}`
        );
      return {
        ...state,
        challenges: state.challenges.push(payload.challenge),
      };

    case DATA.REMOVE_CHALLENGE:
      const remState = state;
      remState.challenges.splice(payload.index);
      if (debugs)
        console.log(`dataReducer | new state: ${JSON.stringify(remState)}`);
      return remState;

    case DATA.UPDATE_CHALLENGE:
      const upState = state;
      upState.challenges[payload.index] = payload.challenge;
      if (debugs)
        console.log(`dataReducer | new state: ${JSON.stringify(upState)}`);
      return upState;

    default:
      throw new Error(
        `No action type for DataReducer: ${JSON.stringify(type)}`
      );
  }
};
