import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../../app/api/apiSlice";

export const challengeApiSlice = apiSlice.injectEndpoints({
  reducerPath: "challenges",
  //baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:4000" }),
  tagTypes: ["Challenges"],
  endpoints: (builder) => ({
    getChallenges: builder.query({
      query: () => "/api/challenges",
      transformResponse: (res) => res.sort((a, b) => a.id - b.id),
      providesTags: ["Challenges"],
    }),
    addChallenge: builder.mutation({
      query: (todo) => ({
        url: "/api/challenges",
        method: "POST",
        body: todo,
      }),
      invalidatesTags: ["Challenges"],
    }),
    deleteChallenge: builder.mutation({
      query: ({ id }) => ({
        url: `/challenges/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Challenges"],
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useAddChallengeMutation,
  useDeleteChallengeMutation,
} = challengeApiSlice;
