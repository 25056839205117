import { useState, useEffect } from "react";
import { VscSettingsGear } from "react-icons/vsc";
import { Link } from "react-router-dom";

function SettingsButton() {
  return (
    <Link to="/settings" className="btn btn-ghost m-2">
      <VscSettingsGear size="24px" />
    </Link>
  );
}

export default SettingsButton;
