import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  reducerPath: "user",
  tagTypes: ["User"],
  endpoints: (builder) => ({
    login: builder.query({
      query: (authCode) => `/api/sf/auth/user?code=${authCode}`,
      transformResponse: (res) => res.data,
    }),
  }),
});

export const { useLoginQuery } = userApiSlice;
