import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserContext from "../../context/UserContext";
import DataContext from "../../context/DataContext";
import { userChallengesData } from "../../data/example/db-user-challenges";
import { call_setUserInfoFromCode } from "../../utils/serverCallouts";

import { useLoginQuery } from "../../features/user/userApiSlice";
function Callback() {
  const authParam = "code";
  const { user, updateUserInfo, logIn, logOut, setLoginError, setChallenges } =
    useContext(UserContext);
  //Testing
  const dataContext = useContext(DataContext);
  const setDefaultChallenges = dataContext.setChallenges;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let orgDomain;

  let paramCode;
  if (searchParams.has(authParam)) {
    paramCode = searchParams.get(authParam);
    if (paramCode) {
      orgDomain = document.referrer;
    }
  }
  console.log("Callback paramCode", paramCode);
  const {
    data: userAuth,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useLoginQuery(paramCode);

  useEffect(() => {
    //console.log("Callback | useEffect[navigate]", user);
    // call_setUserInfoFromCode(
    //   paramCode,
    //   user,
    //   updateUserInfo,
    //   logIn,
    //   logOut,
    //   setLoginError,
    //   setChallenges,
    //   userChallengesData,
    //   orgDomain
    // );
  }, [navigate]);

  useEffect(() => {
    console.log("Callback | useEffect[user.returnPage]", user.returnPage);

    //navigate(user.returnPage);
  }, [user.returnPage]);

  return <>{JSON.stringify(userAuth)}</>;
}

export default Callback;
