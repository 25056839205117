import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "../features/theme/themeSlice";
import userReducer from "../features/user/userSlice";
import { saveLocal } from "../utils/localStorage";
import { apiSlice } from "./api/apiSlice";

const localStorageMiddleware = (store) => (next) => (action) => {
  console.log("Dispatch:", action, store.getState());
  let key;
  if (action.type.includes("theme/")) {
    key = "theme";
  } else if (action.type.includes("user/")) {
    key = "user";
  }
  if (key) saveLocal(key, action.payload);
  next(action);
};

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    user: userReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddlewar) =>
    getDefaultMiddlewar().concat(apiSlice.middleware, localStorageMiddleware),
  devTools: true,
});
