import { createSlice } from "@reduxjs/toolkit";
import { loadLocal } from "../../utils/localStorage";

const initialState = loadLocal("theme") || "corporate";

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const selectTheme = (state) => state.theme;

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
