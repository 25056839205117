function PanelContainer({ children }) {
  return (
    <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
      <div className="m-1 h-full rounded border-[1px] border-base-100 bg-base-200 p-4 shadow-xl">
        {children}
      </div>
    </div>
  );
}

export default PanelContainer;
