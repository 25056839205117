import React from "react";
import Hint from "../Common/Hint";

function ChallengePanel({ challenge }) {
  let renderedTags = challenge?.tags?.map((tag) => {
    const badgeClass =
      tag === "Apex"
        ? "badge-primary"
        : tag === "Generic"
        ? "badge-secondary"
        : tag === "DML"
        ? "badge-accent"
        : "badge-error";

    return (
      <span key={tag} className={`badge ${badgeClass} mr-1 cursor-pointer`}>
        {tag}
      </span>
    );
  });

  const renderedParams = challenge
    ? Object.keys(challenge?.method_params).map((param) => (
        <div key={param}>
          {param} ({challenge?.method_params[param]})
        </div>
      ))
    : "";

  const renderedTests = challenge
    ? challenge.validation?.map((test, i) => {
        const args = [];
        Object.keys(test).forEach((arg) => {
          if (arg !== "return") args.push(test[arg]);
        });

        //console.log(args);

        return (
          <React.Fragment key={i}>
            <p className="col-span-1 mx-2 border-t-[1px] border-base-300">
              {i + 1}
            </p>
            <p className="col-span-3 mx-2 border-t-[1px] border-base-300">{`${
              challenge.method_name
            }(${args.toString()})`}</p>
            <p className="col-span-2 mx-2 border-t-[1px] border-base-300">
              {test.return}
            </p>
          </React.Fragment>
        );
      })
    : "";

  const renderedHints = challenge
    ? challenge.hints?.map((hint, i) => (
        <Hint key={hint.title} hint={{ title: hint.title, body: hint.body }} />
      ))
    : null;
  return (
    <>
      <h1 className="my-2 text-center text-2xl font-bold">
        {challenge?.title}
      </h1>
      <div className="my-2 flex justify-center">{renderedTags}</div>
      <div className="my-2">
        <div className="divider">Brief</div>
        <p>{challenge?.description}</p>
        <div className="divider">Requirements</div>
        <div className="overflow-x-auto">
          <table className="w-full table-fixed">
            <tbody>
              <tr>
                <th className="flex justify-end">Method</th>
                <td>
                  <p className="mx-2">{challenge?.method_name}()</p>
                </td>
              </tr>
              <tr>
                <th className="flex justify-end">Parameters</th>
                <td>
                  <div className="mx-2">{renderedParams}</div>
                </td>
              </tr>
              <tr>
                <th className="flex justify-end">Return type</th>
                <td>
                  <p className="mx-2">{challenge?.method_return}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="divider">Test cases</div>
        <div className="overflow-x-auto">
          <div className="mx-2 grid min-w-max grid-cols-6">
            <p className="col-span-1 mx-2 font-bold">#</p>
            <p className="col-span-3 mx-2 font-bold">Input</p>
            <p className="col-span-2 mx-2 font-bold">Output</p>
            {renderedTests}
          </div>
        </div>
        {challenge?.hints && <div className="divider">Tips</div>}
        {renderedHints}
      </div>

      {/* <span className="float-bottom float-right text-xs bg-blue-400 rounded px-2 py-1 text-white btn btn-ghost">
        <VscBookmark size="18px" />
      </span> */}
    </>
  );
}

export default ChallengePanel;
