function Title({ title }) {
  return (
    <div className="mb-4 flex justify-center">
      <div className="max-w-md">
        <h1 className="text-5xl font-bold">{title}</h1>
      </div>
    </div>
  );
}

export default Title;
