import { useState, useEffect } from "react";
import PanelContainer from "../Common/PanelContainer";

function NewChallengeForm() {
  const [postStatus, setPostStatus] = useState("");
  const [form, setForm] = useState({
    title: "Life advice",
    description: "That I asked for",
    hints: ["Eat your vegatables"],
    difficulty: 1,
    tags: ["Apex"],
    method_name: "newForm",
    method_params: { a: "Integer" },
    method_return: "Integer",
    apex: ["Integer print(Integer a) {", "\t // Code here", "}"],
    test: ["// Test class here"],
    solution: ["Integer sum(Integer a, Integer b) {", "\treturn a + b;", "}"],
    validation: [{ a: 1, b: 1, return: 2 }],
    contributor: "form-add@deloitte.co.uk",
  });

  const fieldData = [
    {
      label: "Title",
      key: "title",
      type: "text",
    },
    {
      label: "description",
      key: "description",
      type: "text",
    },
    {
      label: "hints",
      key: "hints",
      type: "array",
    },
    {
      label: "difficulty",
      key: "difficulty",
      type: "text",
    },
    {
      label: "tags",
      key: "tags",
      type: "array",
    },
    {
      label: "method_name",
      key: "method_name",
      type: "text",
    },
    {
      label: "method_params",
      key: "method_params",
      type: "object",
    },
    {
      label: "method_return",
      key: "method_return",
      type: "text",
    },
    {
      label: "apex",
      key: "apex",
      type: "code",
    },
    {
      label: "test",
      key: "test",
      type: "code",
    },
    {
      label: "solution",
      key: "solution",
      type: "code",
    },
    {
      label: "validation",
      key: "validation",
      type: "array",
    },
    // {
    //   label: "contributor",
    //   key: "contributor",
    //   type: "text",
    // },
  ];
  const arrayFields = [
    "hints",
    "tags",
    "method_params",
    "apex",
    "test",
    "solution",
    "validation",
  ];

  function handleChange(e) {
    // if (arrayFields.includes(e.target.id)) {
    //   setForm({
    //     ...form,
    //     [e.target.id]: Array.from(e.target.value),
    //   });
    // } else {
    //   setForm({
    //     ...form,
    //     [e.target.id]: e.target.value,
    //   });
    // }
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  }

  function handleSave() {
    setPostStatus("Saving...");
    //console.log(form);
    postChallenge();
  }

  async function postChallenge() {
    const body = { ...form };

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    const serverHost = window.location.host.includes("localhost")
      ? process.env.REACT_APP_SERVER_HOST_DEV
      : process.env.REACT_APP_SERVER_HOST_PROD;
    const urlChallenge = `${serverHost}/api/db`;

    try {
      //console.log("Posting...");
      let response = await fetch(urlChallenge, options);
      setPostStatus("Saved!");
      //console.log("Posted");
    } catch (err) {
      //console.log("Failed posting...", err);
      setPostStatus("Failed to save!");
    } finally {
      //console.log("Finally post...");
      setPostStatus("");
    }
    //console.log("Post END");
  }
  function field(data) {
    switch (data.type) {
      case "text":
        return (
          <>
            <span className="flex justify-center">{data.label}</span>
            <input
              type="text"
              placeholder="text"
              className="input-bordered input mb-4 mt-2"
              id={data.key}
              value={form[data.key]}
              onChange={handleChange}
            />
          </>
        );
      case "textarea":
        return (
          <>
            <span className="flex justify-center">{data.label}</span>
            <textarea
              className="textarea-bordered textarea mb-4 mt-2"
              placeholder="textarea"
              id={data.key}
              value={form[data.key]}
              onChange={handleChange}
            ></textarea>
          </>
        );
      case "array":
        return (
          <>
            <span className="flex justify-center">{data.label}</span>
            <textarea
              className="textarea-bordered textarea mb-4 mt-2"
              placeholder="array"
              id={data.key}
              value={form[data.key]}
              onChange={handleChange}
            ></textarea>
          </>
        );
      case "code":
        return (
          <>
            <span className="flex justify-center">{data.label}</span>
            <textarea
              className="textarea-bordered textarea mb-4 mt-2"
              placeholder="code"
              id={data.key}
              value={form[data.key]}
              onChange={handleChange}
            ></textarea>
          </>
        );
      case "object":
        return (
          <>
            <span className="flex justify-center">{data.label}</span>
            <textarea
              className="textarea-bordered textarea mb-4 mt-2"
              placeholder="object"
              id={data.key}
              value={form[data.key]}
              onChange={handleChange}
            ></textarea>
          </>
        );
      default:
        return <div>NOTHIN</div>;
    }
  }

  const renderedFields = fieldData.map((data) => field(data));
  const saveButton = (
    <button className="btn-primary btn mb-4 w-full" onClick={handleSave}>
      Save
    </button>
  );
  return (
    <div className="flex justify-center">
      <div className="card w-1/3 bg-base-200 shadow-xl">
        <div className="card-body">
          {saveButton}
          {postStatus}
          <div className="form-control">{renderedFields}</div>
          {saveButton}
        </div>
      </div>
    </div>
  );
}

export default NewChallengeForm;
