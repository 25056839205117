export function codeToArray(input) {
  return input?.split("\n") || "";
}

export function arrayToCode(input) {
  //console.log("codeParser | arrayToCode", input);
  let output = "";
  if (input && Array.isArray(input)) {
    input?.forEach((line) => (output += line + "\n"));
  } else {
    //console.log("codeParser | arrayToCode | invald", input);
  }
  return output;
}

export function arrayToString(input, removeComments = true) {
  //console.log("codeParser | arrayToString", input, removeComments);
  let output = "";
  if (input && Array.isArray(input)) {
    if (removeComments) {
      input = filterComments(input);
    }
    output = input.join("");
  } else {
    //console.log("codeParser | arrayToCode | invald", input);
  }
  //console.log("codeParser | arrayToString | output", output);
  return output;
}

function filterComments(input, chars = "//") {
  return input.filter((line) => !line.includes(chars));
}
