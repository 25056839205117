import React, { useState, useEffect, useRef, useContext } from "react";
import Title from "../../components/Common/Title";
import UserContext from "../../context/UserContext";
import { MdContentCopy, MdCheck, MdLogout } from "react-icons/md";
import {
  call_refreshAccessToken,
  call_revokeAccessToken,
} from "../../utils/serverCallouts";
function SessionInfo() {
  const { user, updateUserInfo, logOut, setUserInfo } = useContext(UserContext);
  const [copied, setCopied] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [saveButtonClass, setSaveButtonClass] = useState("main");

  const saveMessage = (
    <p className="text-lg font-semibold text-success text-success-content">
      <em>Copied</em>
    </p>
  );

  useEffect(() => {
    //console.log("SessionInfo | UE", user?.userInfo?.sf_access_token);
  }, []);

  function handleCopyClicked(field) {
    const data = user.userInfo[field];
    navigator.clipboard.writeText(data || "");
    // setShowMessage(true);
    setCopied(field);
    setTimeout(() => {
      //setShowMessage(false);
      setCopied("");
    }, 1000);
  }

  function handleRefreshTokenClicked() {
    setSaveButtonClass("disabled");
    setShowMessage(true);
    call_refreshAccessToken(user, updateUserInfo);
    setTimeout(() => {
      setShowMessage(false);
      setSaveButtonClass("main");
    }, 1000);
  }

  function handleRemoveAccessTokenClicked() {
    //console.log("REMOVE CLICKED");
    setUserInfo({
      ...user.userInfo,
      sf_access_token: "1234",
    });
  }

  const renderedItems = Object.keys(user.userInfo).map((k) => (
    <div key={k} className="form-control">
      <label className="label">
        <span className="label-text">{k}</span>
        {copied === k && (
          <span className="label-text-alt text-success text-success-content">
            Copied
          </span>
        )}
      </label>
      <label className="input-group">
        <input
          type="text"
          className="input-bordered input max-w-md rounded-r-none"
          value={user.userInfo[k] || ""}
          readOnly
        />
        <span
          onClick={() => handleCopyClicked(k)}
          className="btn-secondary btn"
        >
          <MdContentCopy />
        </span>
      </label>
    </div>
  ));

  const refreshButton = (
    <button
      className={`btn btn-${saveButtonClass} mx-auto mt-4`}
      onClick={handleRefreshTokenClicked}
    >
      Refresh Access Token
    </button>
  );
  const revokeButton = (
    <button
      className={`btn btn-${saveButtonClass} mx-auto mt-4`}
      onClick={() => call_revokeAccessToken(user, logOut)}
    >
      Revoke Access Tokens
    </button>
  );
  const removeButton = (
    <button
      className={`btn btn-${saveButtonClass} mx-auto mt-4`}
      onClick={() => handleRemoveAccessTokenClicked()}
    >
      Remove Access Token
    </button>
  );

  return (
    <>
      <Title title="Session Info" />
      {!user.loggedIn && (
        <div className="full-center">
          <h1 className="mx-auto text-2xl font-bold">
            Please log in to see Session Information.
          </h1>
        </div>
      )}
      {user.loggedIn && (
        <div className="flex h-fit">
          <div className="mx-auto rounded p-4 shadow">
            <div className="flex h-fit">
              <div className="mx-auto w-full">
                <div className="flex h-fit">{removeButton}</div>
                {renderedItems}
                <div className="flex h-fit">{refreshButton}</div>
                <div className="flex h-fit">{revokeButton}</div>
              </div>
            </div>
            {showMessage && (
              <div className="m-2 flex justify-center">{saveMessage}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SessionInfo;
