import { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { SiSalesforce } from "react-icons/si";
import { MdLogout } from "react-icons/md";
import {
  sfProductionURL,
  sfSandboxURL,
  sfDomainURL,
} from "../../utils/endpoints";

function LoginButton() {
  const { pathname } = useLocation();
  const { user, logOut, setLoggingIn, setReturnPage } = useContext(UserContext);

  const handleLoginClick = (orgType) => {
    setLoggingIn(true);
    setReturnPage(pathname);
    //console.log("LoginButton | handleLoginClick |", user.returnPage, orgType);
    switch (orgType) {
      case "PROD":
        window.location.href = sfProductionURL;
        break;
      case "SANDBOX":
        window.location.href = sfSandboxURL;
        break;
      case "MYDOMAIN":
        window.location.href = sfProductionURL;
        break;
      default:
        break;
    }
  };

  const myDomainButton = user.userInfo.sf_mydomain ? (
    <a href={sfDomainURL(user.userInfo.sf_mydomain)} tabIndex={0}>
      {user.userInfo.sf_mydomain}
    </a>
  ) : (
    <Link to={"/settings"} tabIndex={0} className="text-content-error bg-error">
      My Domain
    </Link>
  );

  function getButton() {
    if (user.loggedIn) {
      return (
        <>
          <button className="btn-error btn flex md:hidden" onClick={logOut}>
            <MdLogout />
          </button>
          <button className="btn-error btn hidden md:flex" onClick={logOut}>
            Log out
          </button>
        </>
      );
    } else if (user.loggingIn) {
      return (
        <button className="btn-disabled btn" tabIndex="-1">
          Logging in...
        </button>
      );
    } else {
      return (
        <div className="dropdown-end dropdown">
          <label className="btn-primary btn flex md:hidden" tabIndex={0}>
            <SiSalesforce />
          </label>
          <label className="btn-primary btn hidden md:flex" tabIndex={0}>
            <SiSalesforce className="mr-1" />
            Log in
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box w-52 bg-base-100 p-2 text-base-content shadow"
          >
            <li>
              <button onClick={() => handleLoginClick("PROD")} tabIndex={0}>
                Production
              </button>
            </li>
            <li>
              <button onClick={() => handleLoginClick("SANDBOX")} tabIndex={0}>
                Sandbox
              </button>
            </li>
            <li>{myDomainButton}</li>
          </ul>
        </div>
      );
    }
  }

  return getButton();
}

export default LoginButton;
