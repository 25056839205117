import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sf_username: null,
  sf_org: null,
  sf_org_type: null,
  sf_access_token: null,
  sf_refresh_token: null,
  sf_mydomain: null,
  challenges_modified: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      Object.keys(action.payload).map(
        (key) => (state[key] = action.payload[key])
      );
    },
    addChallengeModified: {
      reducer(state, action) {
        state.challenges_modified.push(action.payload);
      },
      prepare(challenge) {
        return {
          payload: {
            lastModified: new Date(),
            challenge,
          },
        };
      },
    },
    resetUser(state) {
      return { ...initialState };
    },
  },
});

export const selectUserAll = (state) => state.user;
export const selectUserInfo = (state) => {
  let { challenges_modified, ...rest } = state.user;
  return rest;
};
export const selectUserChallenges = (state) => state.user.challenges_modified;

export const { updateUser, addChallengeModified, resetUser } =
  userSlice.actions;

export default userSlice.reducer;
