import Title from "../../components/Common/Title";
import CalloutBuilder from "../../components/CalloutBuilder";

function ApiPage() {
  return (
    <>
      <Title title="REST" />
      <CalloutBuilder />
    </>
  );
}

export default ApiPage;
