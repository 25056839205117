import { useEffect, useState, useRef } from "react";
import Tabs from "./Tabs";
import Editor from "@monaco-editor/react";
import { arrayToCode, codeToArray } from "../../utils/codeParser";
import { VscCode, VscBeaker } from "react-icons/vsc";

function CodingPanel({
  defaultChallengeData,
  currentApexInput,
  currentTestInput,
}) {
  const [activeTab, setActiveTab] = useState("Apex Class");
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const apexSource = currentApexInput.current
    ? currentApexInput.current
    : defaultChallengeData?.apex;

  const testSource = currentTestInput.current
    ? currentTestInput.current
    : defaultChallengeData?.test;

  const codeSource = activeTab === "Apex Class" ? apexSource : testSource;

  const renderedCode = arrayToCode(codeSource);

  function handleEditorChange(value) {
    // //console.log("CodingPanel | handleEditorChange", value);
    if (activeTab === "Apex Class") {
      currentApexInput.current = codeToArray(value);
      // //console.log("CodingPanel | currentApexInput", currentApexInput.current);
    } else {
      currentTestInput.current = codeToArray(value);
      // //console.log("CodingPanel | currentTestInput", currentTestInput.current);
    }
  }

  const tabs = {
    "Apex Class": <VscCode />,
    "Test Class": <VscBeaker />,
  };

  const tabsClass = {
    true: "w-5 h-5 mr-2 text-primary",
    false: "w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500",
  };

  const buttonClass = {
    true: "inline-flex p-4 text-primary border-b-2 border-primary active group w-full justify-center",
    false:
      "inline-flex p-4 border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 group w-full justify-center",
  };
  function handleEditorValidation(markers) {
    // model markers
    //console.log("handleEditorValidation", markers);
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  }
  return (
    <>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsClass={tabsClass}
        buttonClass={buttonClass}
      />
      <div className="p-2" />
      <Editor
        height="48vh"
        path={`${activeTab}${Math.random()}`}
        defaultLanguage="apex"
        defaultValue={renderedCode}
        //onMount={handleEditorDidMount}
        onChange={handleEditorChange}
        onValidate={handleEditorValidation}
        options={{
          wordWrap: "on",
          minimap: {
            enabled: false,
          },
        }}
      />
    </>
  );
}

export default CodingPanel;
