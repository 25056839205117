import { VscCheck, VscChromeClose } from "react-icons/vsc";

function TestResult({ result }) {
  if (typeof result === "string") {
    const statusClass = "bg-error text-error-content";
    const iconClass = "bg-error text-error-content";
    const statusIcon = <VscChromeClose />;
    return (
      <div
        className={`${statusClass} m-2 w-auto rounded border border-b-2 p-4 shadow`}
      >
        <div className="flex">
          <div className="w-2/3">
            <p className="text-l font-semibold">{`Unable to run tests`}</p>
          </div>
          <div className="w-1/3">
            <span
              className={`${iconClass} text-s float-right rounded border px-2 py-1`}
            >
              {statusIcon}
            </span>
          </div>
        </div>
        <div className="text-md py-4">{result}</div>
      </div>
    );
  }
  const statusClass = result?.success
    ? "bg-success text-success-content"
    : "bg-error text-error-content";
  const iconClass = result?.success
    ? "bg-success text-success-content"
    : "bg-error text-error-content";
  const statusIcon = result?.success ? <VscCheck /> : <VscChromeClose />;
  return (
    <div
      className={`${statusClass} m-2 w-auto rounded border border-b-2 p-4 shadow`}
    >
      <div className="flex">
        <div className="w-2/3">
          <p className="text-xl font-semibold">{`Test #${result.id}`}</p>
        </div>
        <div className="w-1/3">
          <span
            className={`${iconClass} text-s float-right rounded border px-2 py-1`}
          >
            {statusIcon}
          </span>
        </div>
      </div>
      {/* <div className="py-4 text-md">Test description here</div> */}
      {!result?.success && (
        <div className="text-md py-4">
          <p>
            Expected: <b>{result.expected}</b>
          </p>
          <p>
            Result: <b>{result.result}</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default TestResult;
