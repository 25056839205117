import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/Common/Title";

function HowPage() {
  return (
    <div className="hero min-h-full bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img
          src="https://picsum.photos/200/300"
          className="max-w-sm rounded-lg shadow-2xl"
        />
        <div>
          <Title title="About" />
          <p className="py-6">I've got nothing better to do</p>
          <Link to="/challenges" className="btn-primary btn">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HowPage;
