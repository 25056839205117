import { Outlet } from "react-router-dom";
import NavbarTop from "./Main/NavbarTop";
import Footer from "./Main/Footer";
function Layout() {
  return (
    <div className="flex h-screen flex-col">
      <NavbarTop />
      <main className="flex-grow p-1">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
