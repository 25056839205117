import Content from "../../components/Common/Content";
import Title from "../../components/Common/Title";
import Callback from "../../components/Service/Callback";

function CallbackPage() {
  return (
    <>
      <main id="main" className="main">
        <Title title="Oauth2 Callback" />
        <Content>Nothing to see here...</Content>
        <Callback />
      </main>
    </>
  );
}

export default CallbackPage;
