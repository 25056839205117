import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { UserProvider } from "./context/UserContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { DataProvider } from "./context/DataContext";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { challengeApiSlice } from "./features/challenges/challengesApiSlice";
import { Provider } from "react-redux";
import { store } from "./app/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="">
    <UserProvider>
      <DataProvider>
        <ApiProvider api={challengeApiSlice}>
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </BrowserRouter>
          </Provider>
        </ApiProvider>
      </DataProvider>
    </UserProvider>
  </div>
);
