import { useParams } from "react-router-dom";
import Title from "../../components/Common/Title";
import ChallengeContainer from "../../components/Challenges/ChallengeContainer";

function ChallengePage() {
  const { id } = useParams();
  const title = `Challenge #${id}`;
  return (
    <>
      <Title title={title} />
      <ChallengeContainer challengeId={id} />
    </>
  );
}

export default ChallengePage;
