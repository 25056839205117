import { useState, useEffect } from "react";

import { useContext } from "react";
import UserContext from "../../context/UserContext";

function UsernameIcon() {
  const { user } = useContext(UserContext);

  return (
    <a
      className="btn-link btn-active btn hidden md:flex"
      href={user.userInfo.sf_profile}
      target="_blank"
      rel="noreferrer"
    >
      {user.userInfo.sf_username}
    </a>
  );
}

export default UsernameIcon;
