import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function ChallengeListRowSkeleton() {
  const idSkeleton = <div className="h-2.5 w-8 rounded-full bg-base-300" />;

  const titleSkeleton = <div className="h-2.5 w-32 rounded-full bg-base-300" />;
  const authorSkeleton = (
    <div className="mt-2 h-2.5 w-24 rounded-full bg-base-300" />
  );
  const tagsSkeleton = (
    <div className="flex">
      <div className="mr-1 h-2.5 w-8 rounded-full bg-base-200"></div>
      <div className="mr-1 h-2.5 w-8 rounded-full bg-base-200"></div>
      <div className="mr-1 h-2.5 w-8 rounded-full bg-base-200"></div>
    </div>
  );
  // const idSkeleton = (
  //   <div className="mb-2.5 h-2.5 w-8 rounded-full bg-gray-300 dark:bg-gray-600" />
  // );

  return (
    <tr className="w-full animate-pulse space-y-4 divide-y divide-base-200 rounded border border-base-200 p-4 shadow md:p-6">
      <th>{idSkeleton}</th>
      <td>
        <span className="font-bold">{titleSkeleton}</span> <br /> {tagsSkeleton}
      </td>
      <td>{titleSkeleton}</td>
      <td>{idSkeleton}</td>
      <td>
        <div className="flex items-center space-x-3">
          <div>
            <div className="font-bold">{titleSkeleton}</div>
            <div className="text-sm opacity-50">{authorSkeleton}</div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default ChallengeListRowSkeleton;
