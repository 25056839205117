import React from "react";

function Tabs({ tabs, tabsClass, activeTab, setActiveTab }) {
  function handleClick(tab) {
    setActiveTab(tab);
  }
  const renderedTabs = Object.keys(tabs).map((tab) => {
    const isActive = tab === activeTab;
    const wClass = `w-1/${Object.keys(tabs).length}` || "w-1/2";
    return (
      <button
        key={tab}
        className={`tab tab-bordered ${isActive ? "tab-active" : ""} ${wClass}`}
        onClick={() => handleClick(tab)}
      >
        {React.cloneElement(tabs[tab], {
          className: `w-5 h-5 mr-2 ${tabsClass[isActive]} `,
        })}
        {tab}
      </button>
    );
  });

  return <div className="tabs flex">{renderedTabs}</div>;
  // const renderedTabs = Object.keys(tabs).map((tab) => {
  //   const isActive = tab === activeTab;
  //   return (
  //     <li key={tab} className={`w-1/${Object.keys(tabs).length}`}>
  //       <button
  //         className={buttonClass[isActive]}
  //         onClick={() => handleClick(tab)}
  //       >
  //         {React.cloneElement(tabs[tab], { className: tabsClass[isActive] })}
  //         {tab}
  //       </button>
  //     </li>
  //   );
  // });

  // return (
  //   <div className="flex border-b border-gray-200 justify-center">
  //     <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 items-center justify-center w-full">
  //       {renderedTabs}
  //     </ul>
  //   </div>
  // );
}

export default Tabs;
