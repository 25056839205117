import { useState, useEffect } from "react";

function ServerStatus() {
  const [serverStatus, setServerStatus] = useState(0);
  const serverHost = window.location.host.includes("localhost")
    ? process.env.REACT_APP_SERVER_HOST_DEV
    : process.env.REACT_APP_SERVER_HOST_PROD;
  const url = `${serverHost}/api/status`;

  useEffect(() => {
    //console.log("STATUS ENTER");
    fetch(url)
      .then(
        (response) => response.json(),
        (bad) => setServerStatus(2)
      )
      .then((result) => {
        const status = result?.data?.status;
        if (status === "live") setServerStatus(1);
        else setServerStatus(2);
      })
      .catch((err) => {
        //console.log("STATUS ERR", err);
        setServerStatus(2);
      });
    //console.log("@@@@@@@@@@@@@@@@@");
  }, []);

  const renderedItem = getRenderedStatus();
  function getRenderedStatus() {
    switch (serverStatus) {
      case 0:
        return (
          <div className="alert alert-info text-info text-info-content shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="h-6 w-6 flex-shrink-0 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>Checking server...</span>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="alert-success-content alert alert-success shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 flex-shrink-0 stroke-current"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>Server online</span>
            </div>
          </div>
        );
      default:
        return (
          <div className="alert alert-error shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 flex-shrink-0 stroke-current"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>Server down</span>
            </div>
          </div>
        );
    }
  }

  return renderedItem;
}

export default ServerStatus;
